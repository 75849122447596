import * as React from 'react';
import i18next from "i18next";
import ContactUsForm from '../../../components/contact-us-form/contact-us-form';


const ContactUs = () => {
    return (
        <section className='p-4r font-s3r'>
            <h1 className="mb-2r text-center">{i18next.t('contactUs.title')}</h1>
            <ContactUsForm></ContactUsForm>
        </section>
    )
};

export default ContactUs;
