import * as React from "react"
import PrimaryButton from "../button/primary-button";
import FormItem from "../form-item/form-item";
import PjIcon from "../icons/svg-icon";
import * as contactUsStyles from './contact-us-form.module.scss';
import i18next from "i18next";

class ContactUsForm extends React.Component {
    render() {
        return (
            <form>
                <div className="row w-80 px-4r">
                    <FormItem required={true} name={'name'} label='Name'>
                        <FormItem.textField id="name" placeholder="First"></FormItem.textField>
                        <FormItem.textField placeholder="Last"></FormItem.textField>
                    </FormItem>
                </div>

                <div className={`${contactUsStyles.contactUs__lastSection} row w-80 px-4r`}>
                    <div className="col-1-of-2 pr-6r">
                        <FormItem className="mb-2r" required={true} name={'email'} label='Email'>
                            <FormItem.emailField id="email" placeholder="Email Address"></FormItem.emailField>
                        </FormItem>
                        <FormItem className="mb-2r" name={'message'} label='Message'>
                            <FormItem.textArea rows={10} className={contactUsStyles.contactUs__message} id="message"
                                               placeholder="Enter your message here..."></FormItem.textArea>
                        </FormItem>
                        <PrimaryButton type='submit'>Submit</PrimaryButton>

                    </div>
                    <div
                        className={`${contactUsStyles.contactUs__locationContainer} col-1-of-2 pl-6r mt-1r pr-4r h-100`}>
                        <h5 className="mb-1r font-s2r">
                            {i18next.t('footer.company.name')}
                        </h5>
                        <address>
                            <p className="mb-1r font-s1-3r">
                                <PjIcon
                                    stroke="black"
                                    iconName="location"
                                    width='1.3em'
                                    height='1.3em'/>
                                <span> {i18next.t('footer.company.location')}</span>
                            </p>
                            <p className="mb-1r font-s1-3r">
                                <PjIcon
                                    stroke="black"
                                    iconName="phone"
                                    width='1.3em'
                                    height='1.3em'/>
                                {i18next.t('footer.company.contact')}
                            </p>
                        </address>

                        <iframe
                            loading="lazy"
                            allowFullScreen={false}
                            title='Contact Us'
                            referrerPolicy="no-referrer-when-downgrade"
                            className={`${contactUsStyles.contactUs__map} w-100`}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1765.6265208894863!2d85.39002660695805!3d27.740340190906384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1befbe3daf7f%3A0x67b54d08796835d5!2sPrajanya%20Tech!5e0!3m2!1sen!2snp!4v1652158546647!5m2!1sen!2snp"
                            style={{border: 0}}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

export default ContactUsForm;
