import * as React from 'react';
import Label from '../label/label';
import EmailField from './email-field';
import TextArea from './text-area';
import TextField from './text-field';

const FormItem = ({ className, children, label, required, name }) => {
    return (
        <div className={className}>
            <div className="row">
                <Label required={required} htmlFor={name}>{label}</Label>
            </div>
            <div className="row">
                {children?.length ?
                    children?.map((child, index) => {
                        return <div
                            key={`form-item-input-${index}`}
                            className={`col-1-of-${children?.length} ${index === 0 ? 'pr-6r' : index === children?.length - 1 ? 'pl-6r' : 'px-6r'}`}
                        >
                            {child}
                        </div>
                    }) : children}
            </div>
        </div>
    )
}

export default Object.assign(FormItem, {
    textField: TextField,
    emailField: EmailField,
    textArea: TextArea
});
