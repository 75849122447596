import * as React from 'react';
import * as labelStyle from './label.module.scss';
export const Label = ({ children, required, ...props }) => {
    return (
        <label {...props} className='font-s1-6r font-w6' type="text">
            {children}
            {
                required === true ? <sup className={labelStyle.label__required}>*</sup> : <span></span>
            }
        </label>
    );
}
export default Label;
